import React from "react";
import styled from "styled-components";
import SectionContainer from "../../components/SectionContainer/SectionContainer";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import H3 from "../../components/H3/H3";
import fluidTypography from "../../utils/fluidTypography";

const StyledSectionContainer = styled(SectionContainer)`
  background-color: #f2f2f2;
`;

const StyledH3 = styled(H3)`
  margin-bottom: 0.5rem;
`;
const Base = styled.div`
  margin-bottom: 0.5rem;
  ${fluidTypography(320, 1980, 16, 28)};
`;

const Date = styled(Base)``;
const Name = styled(Base)``;
const Address = styled(Base)``;

const UpcomingEventSection = ({
  data: { title, date, locationName, locationAddress },
}) => {
  return (
    <StyledSectionContainer>
      <SectionTitle title="Upcoming Event" />
      <StyledH3>{title}</StyledH3>
      {date !== "Invalid date" && <Date>{date}</Date>}
      <Name>{locationName}</Name>
      <Address>{locationAddress}</Address>
    </StyledSectionContainer>
  );
};

export default UpcomingEventSection;
