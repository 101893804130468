import React from "react";
import Img from "gatsby-image";
import SectionContainer from "../../components/SectionContainer/SectionContainer";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import styled from "styled-components";
import mq from "../../utils/mq";
import fluidTypography from "../../utils/fluidTypography";

const Base = styled.h3`
  font-weight: bold;
  ${fluidTypography(320, 1980, 18, 23)};
`;

const PanelistContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto 2rem;
  display: grid;
  grid-template-areas: "img" "text";

  ${mq.a992} {
    max-width: var(--max-width-desktop);
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "img text"
      "img text";
    grid-gap: 3rem;
  }
`;

const TextContainer = styled.div`
  grid-area: text;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Name = styled(Base)`
  margin-top: 0.5rem;

  ${mq.a992} {
    margin-top: 0;
  }
`;

const Position = styled(Base)`
  margin-bottom: 1rem;
`;

const Bio = styled.p`
  ${fluidTypography(320, 1980, 16, 20)};
  white-space: pre-line;
`;

const ImgContainer = styled.div`
  width: 100%;
  height: auto;
  grid-area: img;
`;

const PanelistSection = ({ data: { featuredItems } }) => {
  return (
    <SectionContainer>
      <SectionTitle title="Featured Guests" />
      {featuredItems.map((i, index) => (
        <PanelistContainer key={index}>
          <ImgContainer>
            <Img fluid={i.image.childImageSharp.fluid} />
          </ImgContainer>
          <TextContainer>
            <Name>{i.name}</Name>
            <Position>{i.position}</Position>
            <Bio>{i.biography}</Bio>
          </TextContainer>
        </PanelistContainer>
      ))}
    </SectionContainer>
  );
};

export default PanelistSection;
