import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import HeroSection from "../components/heroSection/heroSection";
import UpcomingEventSection from "../sections/home/UpcomingEventSection";
import LocationSection from "../sections/home/LocationSection";
import FeaturedSection from "../sections/home/FeaturedSection";
import AgendaSection from "../sections/home/AgendaSection";
import Layout from "../components/Layout";
import PastEventsSection from "../sections/home/PastEventsSection";

export const IndexPageTemplate = ({
  heroSection,
  upcomingEventSection,
  agendaSection,
  featuredSection,
  locationSection,
}) => (
  <>
    <HeroSection data={heroSection} />
    <UpcomingEventSection data={upcomingEventSection} />
    {agendaSection.active && <AgendaSection data={agendaSection} />}
    {featuredSection.active && <FeaturedSection data={featuredSection} />}
    <LocationSection data={locationSection} />
    <PastEventsSection />
  </>
);

IndexPageTemplate.propTypes = {
  heroSection: PropTypes.object.isRequired,
  upcomingEventSection: PropTypes.object.isRequired,
  agendaSection: PropTypes.object.isRequired,
  featuredSection: PropTypes.object.isRequired,
  locationSection: PropTypes.object.isRequired,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        heroSection={frontmatter.heroSection}
        upcomingEventSection={frontmatter.upcomingEventSection}
        agendaSection={frontmatter.agendaSection}
        featuredSection={frontmatter.featuredSection}
        locationSection={frontmatter.locationSection}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heroSection {
          heroImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          description
        }
        upcomingEventSection {
          title
          date
          locationName
          locationAddress
        }
        agendaSection {
          active
          agendaItems {
            title
            timeRange
          }
          rsvpLink
        }
        featuredSection {
          active
          featuredItems {
            name
            position
            biography
            image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        locationSection {
          date
          eventTimeRange
          rsvpLink
          venueName
          venueAddress
          coordinates {
            latitude
            longitude
          }
          parkingBlurb
        }
      }
    }
  }
`;
